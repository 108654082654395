import "./App.css";
import Navbar from "./component/Navbar";

import React, { Component } from "react";
import News from "./component/News";
import LoadingBar from "react-top-loading-bar";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default class App extends Component {
  // c = "ankit"
  pageSize = 5;
  // apikey = process.env.REACT_APP_NEW_API;
  // apikey = "ea9934722fb147f99476f4d5f248111d";

  state = {
    progress: 0,
  };

  setProgress = (progress) => {
    this.setState({ progress: progress });
  };
  render() {
    return (
      <Router>
        <div>
          <Navbar />
          <LoadingBar
            color="#f11946"
            height={3}
            progress={this.state.progress}
          />

          {/* <News apikey={this.apikey} setProgress={this.setProgress} pageSize={this.pageSize} country="in" category="science" /> */}

          {/* hello my fast react class besed Component{" "+this.c} */}
        </div>

        <Switch>
          {/* if we remove exact and key=""  (then component is not mount) and (if we add exact exact then component is update but not mount or remount)*/}
          <Route exact path="/">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="general"
              pageSize={this.pageSize}
              country="in"
              category="general"
            />
          </Route>
          <Route exact path="/business">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="business"
              pageSize={this.pageSize}
              country="in"
              category="business"
            />
          </Route>
          <Route exact path="/entertainment">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="entertainment"
              pageSize={this.pageSize}
              country="in"
              category="entertainment"
            />
          </Route>
          <Route exact path="/general">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="general"
              pageSize={this.pageSize}
              country="in"
              category="general"
            />
          </Route>
          <Route exact path="/health">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="health"
              pageSize={this.pageSize}
              country="in"
              category="health"
            />
          </Route>
          <Route exact path="/science">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="science"
              pageSize={this.pageSize}
              country="in"
              category="science"
            />
          </Route>
          <Route exact path="/sports">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="sports"
              pageSize={this.pageSize}
              country="in"
              category="sports"
            />
          </Route>
          <Route exact path="/technology">
            <News
              /*apikey={this.apikey}*/
              setProgress={this.setProgress}
              key="technology"
              pageSize={this.pageSize}
              country="in"
              category="technology"
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}
